<template>
  <el-row
    type="flex"
    :gutter="gutter"
    :justify="justify"
    :align="align"
    :class="rowClass"
  >
    <slot />
  </el-row>
</template>

<script>
export default {
  props: {
    gutter: {
      type: Number,
      default: 0
    },
    justify: {
      type: String,
      default: 'space-between'
    },
    align: {
      type: String,
      default: 'middle'
    },
    fullWidth: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      rowClass: this.fullWidth ? 'full-width' : 'normal'
    }
  }
}
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>
