<template>
  <el-button :loading="loading" :type="type" ref="button" @click="handleClick">
    <slot />
  </el-button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },
    disable: Boolean,
    loading: Boolean
  },
  methods: {
    blur (e) {
      console.log('button blur')
      let target = e.target
      if (target.nodeName === 'SPAN') {
        target = target.parentNode
      }
      target.blur()
    },
    handleClick (e) {
      this.blur(e)
      this.$emit('click', e)
    }
  }
}
</script>

<style scoped>

</style>
