import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'
import Home from '@/router/home'
import Auth from '@/router/auth'
import Profile from '@/router/profile'

Vue.use(VueRouter)

const routes = [
  Home.Redirect,
  Home.Route,
  Auth.Route,
  Profile.Route,
  {
    path: '/pad/:token',
    name: 'Pad',
    component: () => import(/* webpackChunkName: "pad" */ '@/views/Pad')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '@/views/NotFound')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const authRouteName = [...Auth.Route.children.map(child => child.name)]
const noLoginRouteName = ['NotFound']

router.beforeEach(function (to, from, next) {
  if (noLoginRouteName.indexOf(to.name) !== -1) {
    next()
  } else if (authRouteName.indexOf(to.name) === -1 && Cookies.get('login_status') === undefined) {
    next({ name: 'Login', query: { redirect: to.fullPath } })
  } else if (authRouteName.indexOf(to.name) !== -1 && Cookies.get('login_status') !== undefined) {
    next({ name: 'Home' })
  } else {
    next()
  }
})

export default router
