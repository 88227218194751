const Home = () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
const HomeMain = () =>
  import(/* webpackChunkName: "home" */ '../views/Home/Main/HomeMain.vue')
const FolderMain = () =>
  import(/* webpackChunkName: "home" */ '../views/Home/Main/FolderMain.vue')
const TrashMain = () =>
  import(/* webpackChunkName: "home" */ '../views/Home/Main/TrashMain.vue')

export default {
  Route: {
    path: '/home',
    component: Home,
    children: [
      {
        path: '',
        name: 'Home',
        component: HomeMain
      },
      {
        path: 'folder',
        component: FolderMain
      },
      {
        path: 'trash',
        component: TrashMain
      }
    ]
  },
  Redirect: {
    path: '/',
    redirect: '/home'
  }
}
