const Profile = () => import(/* webpackChunkName: "profile" */ '@/views/Profile')
const SelfInfo = () =>
  import(/* webpackChunkName: "profile" */ '@/views/Profile/Main/SelfInfoMain')
const About = () =>
  import(/* webpackChunkName: "profile" */ '@/views/Profile/Main/AboutMain')

export default {
  Route: {
    path: '/profile',
    component: Profile,
    children: [
      {
        path: '',
        name: 'Profile',
        component: SelfInfo
      },
      {
        path: 'about',
        name: 'About',
        component: About
      }
    ]
  }
}
