const Auth = () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue')
const Login = () =>
  import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue')
const Register = () =>
  import(/* webpackChunkName: "register" */ '../views/Auth/Register.vue')
const FindPassword = () =>
  import(/* webpackChunkName: "findPassword" */ '../views/Auth/FindPassword.vue')

export default {
  Route: {
    path: '/auth',
    component: Auth,
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login
      },
      {
        path: 'register',
        name: 'Register',
        component: Register
      },
      {
        path: 'find_password',
        name: 'FindPassword',
        component: FindPassword
      }
    ]
  }
}
