import Vue from 'vue'
import App from '@/App'
import '@/registerServiceWorker'
import router from '@/router'
import store from '@/store'
import '@/plugins/element'
import '@/plugins/clipboard'
import BaseRow from '@/components/BaseRow'
import BaseButton from '@/components/BaseButton'

Vue.config.productionTip = false

Vue.component('base-row', BaseRow)
Vue.component('base-button', BaseButton)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
